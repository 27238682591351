<script setup>
import { onMounted, ref } from 'vue'
import { useToast } from 'primevue/usetoast'
import { FilterMatchMode } from 'primevue/api'
import ModelService from '@/apps/dailyTech/inventario-ti/services/models.service'
import BrandService from '@/apps/dailyTech/inventario-ti/services/brands.service'
import Icon from '@/components/Icon/index.vue'
import Swal from 'sweetalert2'
import { useStore } from '@/store'

const store = useStore()
const toast = useToast()
const _ModelService = ref(new ModelService())
const _BrandService = ref(new BrandService())
const modalModel = ref(false)
const listModels = ref([])
const listBrands = ref([])
const model = ref({})
const submitted = ref(false)
const isEdit = ref(false)
const filters = ref({
  global: { value: null, matchMode: FilterMatchMode.CONTAINS }
})
const imageModel = ref(null)
const uploadKey = ref(0)

// Obtener el listado de marcas
const fetchBrands = async () => {
  try {
    const { status, data } = await _BrandService.value.getBrands()
    if (status === 200) {
      listBrands.value = data
    }
  } catch (error) {
    console.error(error.message)
  }
}

// Obtener los tipos de modelos
const fetchModels = async () => {
  try {
    const { status, data } = await _ModelService.value.getModels()
    if (status === 200) {
      listModels.value = data
    }
  } catch (error) {
    console.error(error.message)
  }
}

// Abrir el modal
const openModalModel = (item) => {
  modalModel.value = true
  submitted.value = false

  if (item) {
    model.value = { ...item }
    isEdit.value = true
    imageModel.value = item.image
  } else {
    model.value = {
      id: null,
      modelname: '',
      idbrand: null,
      icon: 'material-symbols:devices-off-outline-rounded',
      status: true
    }
    isEdit.value = false
  }
}

// Cerrar el modal
const hideModalModel = () => {
  modalModel.value = false
  submitted.value = false
  model.value = {}
}

// Obtener la imagen del modelo
const getImageModel = (image) => {
  // Detectar si la imagen es object
  if (typeof image === 'object') {
    return URL.createObjectURL(image)
  }

  const token = store.getters['auth/getToken']
  const obj = { image, token }
  return _ModelService.value.getImageModel(obj)
}

// Capturar archivo de la imagen del registro
const onCaptureFile = (e) => {
  console.log('File --->', e.files[0])
  imageModel.value = e.files[0]
}

// Cancelar la subida de la imagen del registro
const cancelUploadImage = () => {
  imageModel.value = model.value.image
  uploadKey.value += 1
}

// Crear un modelo
const createModel = async () => {
  try {
    // Validar que el modelo tenga un nombre y una marca seleccionada
    if (!model.value.modelname || !model.value.idbrand) {
      toast.add({ severity: 'error', summary: 'Error', detail: 'El nombre y la marca son requeridos', life: 3000 })
      return
    }

    const formData = new FormData()
    formData.append('modelname', model.value.modelname)
    formData.append('idbrand', model.value.idbrand)
    formData.append('icon', model.value.icon)
    formData.append('status', model.value.status)
    formData.append('image', imageModel.value)

    const { status } = await _ModelService.value.createModel(formData)
    if (status === 200) {
      toast.add({ severity: 'success', summary: 'Exito', detail: 'Modelo creado exitosamente', life: 3000 })
      await fetchModels()
      hideModalModel()
      modalModel.value = false
      model.value = {}
    }
  } catch (error) {
    console.error(error.message)
  }
}

// Actualizar un modelo
const updateModel = async () => {
  try {
    // Validar que el modelo tenga un id y nombre
    if (!model.value.id || !model.value.modelname) {
      toast.add({ severity: 'error', summary: 'Error', detail: 'El nombre es requerido', life: 3000 })
      return
    }

    const formData = new FormData()
    formData.append('modelname', model.value.modelname)
    formData.append('idbrand', model.value.idbrand)
    formData.append('icon', model.value.icon)
    formData.append('status', model.value.status)
    formData.append('image', imageModel.value)

    const { status } = await _ModelService.value.editModel(model.value.id, formData)
    if (status === 200) {
      toast.add({ severity: 'success', summary: 'Exito', detail: 'Modelo actualizado exitosamente', life: 3000 })
      await fetchModels()
      hideModalModel()
      modalModel.value = false
      model.value = {}
    }
  } catch (error) {
    console.error(error.message)
  }
}

// Guardar o actualizar un modelo
const onSubmit = async () => {
  submitted.value = true
  if (isEdit.value && model.value.id) {
    await updateModel()
  } else {
    await createModel()
  }
}

// Eliminar un modelo
const deleteModel = async (id) => {
  try {
    if (id) {
      Swal.fire({
        icon: 'warning',
        title: 'Advertencia',
        text: 'Desea eliminar el modelo?, esta acción no se puede revertir.',
        showDenyButton: true,
        denyButtonText: 'No, Cancelar',
        confirmButtonText: 'Si, Eliminar'
      }).then((result) => {
        if (result.isConfirmed) {
          _ModelService.value.deleteModel(id).then(({ status }) => {
            if (status !== 200) return

            toast.add({ severity: 'success', summary: 'Exito', detail: 'Modelo eliminado exitosamente', life: 3000 })
            fetchModels()
            hideModalModel()
            modalModel.value = false
            model.value = {}
          }).catch((err) => {
            console.error(err.message)
            if (err.response.status) {
              toast.add({ severity: 'error', summary: 'Error', detail: 'No se ha podido eliminar el modelo', life: 3000 })
            }
          })
        }
      })
    } else {
      toast.add({ severity: 'error', summary: 'Error', detail: 'No se ha podido eliminar el modelo', life: 3000 })
    }
  } catch (error) {
    console.error(error.message)
  }
}

// Hook onMounted
onMounted(async () => {
  await fetchModels()
  await fetchBrands()
})
</script>

<template>
  <Toast />
  <Toolbar class="box mt-4 py-2 px-4">
    <template #start>
      <Button label="Regresar" icon="pi pi-arrow-left" class="p-button-sm" @click="$router.push({ name: 'daily-tech.inventario-ti.configuracion'})"/>
    </template>
    <template #end>
      <Button
        label="Agregar"
        icon="pi pi-plus"
        size="small"
        severity="success"
        @click="openModalModel(null)"
      />
    </template>
  </Toolbar>

  <!-- Listado de los modelos -->
  <div class="box mt-4 intro-x">
    <DataTable
      showGridlines
      ref="dt"
      :value="listModels"
      class="p-datatable-sm p-4 text-sm"
      dataKey="id"
      :paginator="true"
      :rows="10"
      :filters="filters"
      sortField="id"
      :sortOrder="-1"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[5,10,25,50]"
      currentPageReportTemplate="Mostrando registros del {first} al {last} de un total de {totalRecords} registros"
      responsiveLayout="scroll"
    >
      <template #header>
        <div class="table-header p-d-flex p-flex-column p-flex-md-row p-jc-md-between">
          <h3 class="p-mb-2 p-m-md-0 p-as-md-center text-lg">Listado de modelos</h3>
          <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <InputText v-model="filters['global'].value" placeholder="Buscar..." />
          </span>
        </div>
      </template>

      <!-- Columnas -->
      <template #empty>
        <div class="flex flex-col items-center justify-center" style="height: 500px">
          <Icon icon="flat-color-icons:template" class="text-gray-200" style="font-size: 15rem; opacity: 0.3"/>
          <span class="text-gray-400 text-2xl">No se encontraron registros</span>
          <span class="text-gray-300">No hemos encontrado ningún elemento, pruebe con otro filtro u opción</span>
        </div>
      </template>
      <template #loading> Cargando la información, por favor espere... </template>
      <Column field="id" header="Id" :sortable="true"></Column>
      <Column field="modelname" header="Nombre" :sortable="true"></Column>
      <Column field="brandname" header="Marca" :sortable="true"></Column>
<!--      <Column field="icon" header="Icono">
        <template #body="{ data }">
          <Icon :icon="data.icon" style="font-size: 1.5rem;" />
        </template>
      </Column>-->
      <Column
        field="image"
        header="Imagen"
      >
        <template #body="{data}">
          <Image
            v-if="data.image"
            :src="getImageModel(data.image)"
            alt="Image"
            preview
            :pt="{
              root: { class: 'flex justify-center items-center w-10 h-10 bg-gray-200 rounded-md' },
              image: { style: 'width: 35px; height: 35px; object-fit: contain;' }
            }"
          />
          <div v-else class="flex justify-center items-center w-10 h-10 bg-gray-200 rounded-md opacity-50">
            <Icon icon="material-symbols-light:no-photography" class="text-2xl" />
          </div>
        </template>
      </Column>
      <Column field="status" header="Estado" :sortable="true" style="width: 8rem; max-width: 8rem">
        <template #body="{ data }">
          <span :class="'bank-badge px-2 py-1 text-xs font-bold leading-none text-white rounded ' + (data.status === true ? 'bg-green-700' : 'bg-red-700')">{{data.status === true ? 'Activo' : 'Inactivo'}}</span>
        </template>
      </Column>
      <Column :exportable="false" header="Acciones" style="text-align: center; width: 10rem; max-width: 10rem">
        <template #body="{ data }">
          <Button icon="pi pi-pencil" class="p-button-rounded p-button-warning p-mr-2" @click="openModalModel(data)" />
          <Button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="deleteModel(data.id)" />
        </template>
      </Column>
    </DataTable>
  </div>

  <!-- Modal formulario modelos -->
  <Dialog
    v-model:visible="modalModel"
    :style="{width: '450px'}"
    :modal="true"
    class="p-fluid"
    :pt="{
      header: { style: 'padding: 1rem;' }
    }"
  >
    <template #header>
      <h3 :class="{'text-yellow-700': isEdit, 'text-green-700': !isEdit}" class="font-bold text-xl">{{isEdit ? 'Editar' : 'Crear'}} modelo</h3>
    </template>
    <div class="p-field">
      <label for="name">Nombre</label>
      <InputText
        id="name"
        v-model="model.modelname"
        required="true"
        autofocus
        :class="{'p-invalid': submitted && !model.modelname}"
        class="p-fluid"
      />
      <small class="p-error" v-if="submitted && !model.modelname">El nombre es requerido.</small>
    </div>
    <!-- Listado de las marcas -->
    <div class="p-field">
      <label for="brand">Marca</label>
      <Dropdown
        id="brand"
        v-model="model.idbrand"
        :options="listBrands"
        optionLabel="brandname"
        optionValue="id"
        placeholder="Seleccione una opción"
        :filter="true"
        scrollHeight="300px"
        filterPlaceholder="Buscar por nombre"
        autoFilterFocus
        class="w-full"
        :class="{'p-invalid': submitted && !model.idbrand}"
        :pt="{
          panel: { style: 'font-size: 0.75rem; max-width: 100px;' },
          item: { style: 'padding: 0.3rem 0.8rem; line-height: 1.4; word-wrap: break-word; white-space: normal; border-bottom: 1px dashed #eee' }
        }"
      />
      <small class="p-error" v-if="submitted && !model.idbrand">La marca es requerida.</small>
    </div>
    <!-- Icono -->
    <div class="p-field">
      <label for="icon">Icono</label>
      <div class="flex items-center gap-2">
        <InputText
          id="icon"
          v-model="model.icon"
          class="p-fluid"
          autocomplete="off"
        />
        <!-- Visualizar icon en tiempo real -->
        <div class="bg-gray-200 p-2 rounded flex justify-center items-center" style="width: 38px; height: 38px">
          <Icon :icon="model.icon" style="font-size: 2rem;" />
        </div>
      </div>
      <span class="text-xs font-semibold">Buscar mas iconos en <a href="https://icon-sets.iconify.design/" target="_blank" class="text-blue-500">Iconify</a></span>
    </div>
    <!-- Mostrar la imagen del registro -->
    <div class="p-field">
      <label for="icon">Imagen</label>
      <div class="flex flex-wrap justify-center">
        <div class="flex flex-col items-center">
          <div class="flex justify-center items-center bg-gray-200 rounded-md" style="width: 150px; height: 150px">
            <img
              v-if="imageModel"
              :src="getImageModel(imageModel)"
              alt="Imagen del registro"
              class="w-full h-full object-cover rounded-md"
            />
            <Icon v-else :icon="model.icon ? model.icon : 'material-symbols:devices-off-outline-rounded'" style="opacity: 0.4; font-size: 8rem" />
          </div>
          <div class="flex flex-wrap w-full justify-center">
            <!-- Input file para agregar y editar la imagen del registro -->
            <FileUpload
              v-bind:key="uploadKey"
              mode="basic"
              accept="image/*"
              maxFileSize="1000000"
              chooseLabel="Cambiar"
              @select="onCaptureFile"
              :pt="{
                root: { style: 'margin-top: 0.5rem' },
                chooseButton: { style: 'font-size: 0.7rem; padding: 0.2rem 0.5rem' }
              }"
            />
            <!-- Botón para cancelar la imagen del registro -->
            <Button
              v-if="typeof imageModel === 'object'"
              :severity="'danger'"
              text
              size="small"
              style="height: 30px"
              class="mt-2"
              icon="pi pi-times"
              @click="cancelUploadImage"
              v-tippy="{ content: 'Cancelar imagen' }"
            />
          </div>

        </div>
      </div>
    </div>
    <!-- Estado -->
    <div class="p-field">
      <label for="status">Estado</label>
      <InputSwitch v-model="model.status" id="status" class="p-d-block"/>
    </div>

    <template #footer class="p-text-bold">
      <Button label="Cancelar" icon="pi pi-times" class="p-button-danger p-button-sm" @click="hideModalModel"/>
      <Button label="Guardar" icon="pi pi-check" class="p-button-success p-button-sm" @click="onSubmit" />
    </template>
  </Dialog>
</template>

<style scoped>

</style>
